#articleBody img {
    max-width: 100%;
    object-fit: cover;
    height: 100%;
}

#articleBody, #articleBody pre code {
    max-width: 60vw;
    white-space: normal;
}

.a_paragraph {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 200%;
    color: #383838;
    text-indent: 10px;
    margin-top: 20px;
}

.a_paragraph>.inline-code {
    background: rgba(102, 239, 201, 0.38);
    color: #0b856a;
    font-size: 16px;
    padding: 2px 5px;
    border-radius: 5px;
}
.a_paragraph>a {
    color: #0b856a;
}