:root {
	--ck-sample-base-spacing: 2em;
	--ck-sample-color-white: #fff;
	--ck-sample-color-green: #279863;
	--ck-sample-color-blue: #a0ffb4;
	--ck-sample-container-width: 1285px;
	--ck-sample-sidebar-width: 350px;
	--ck-sample-editor-min-height: 200px;
	--ck-sample-editor-max-height: 700px;
	--ck-sample-editor-z-index: 10;
}

/* --------- EDITOR STYLES  ---------------------------------------------------------------------------------------- */

.editor__editable,
	/* Classic build. */
main .ck-editor[role='application'] .ck.ck-content,
	/* Decoupled document build. */
.ck.editor__editable[role='textbox'],
.ck.ck-editor__editable[role='textbox'],
	/* Inline & Balloon build. */
.ck.editor[role='textbox'] {
	width: auto;
	background: #fff;
	font-size: 1em;
	line-height: 1.6em;
	min-height: var(--ck-sample-editor-min-height);
	max-height: var(--ck-sample-editor-max-height);
	padding: 1.5em 2em;
}

.ck-editor[role='application'] {
	overflow: inherit;
}

.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
	flex-wrap: wrap;
}

.ck.ck-editor__editable {
	background: #fff;
	width: 100%;
}

/* Because of sidebar `position: relative`, Edge is overriding the outline of a focused editor. */
.ck.ck-editor__editable {
	position: relative;
	z-index: var(--ck-sample-editor-z-index);
}

.editor-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	position: relative;
	width: 100%;
	justify-content: center;
}

.revision-viewer-container {
	display: none;
}

.revision-viewer-sidebar {
	position: relative;
	min-width: 310px;
	overflow: hidden;
	background: var(--ck-color-toolbar-background);
	border: 1px solid var(--ck-color-toolbar-border);
	margin-left: -1px;
}

/* A case when Pagination and Revision History features are enabled in the editor. */
/* Move the square with page number from the Pagination plugin to the left side, so that it does not cover the RH sidebar. */
body[data-revision-history='true'] .ck.ck-pagination-view-line::after {
	transform: translateX(-100%) !important;
	left: -1px !important;
	right: unset !important;
}

.ck .ck-toolbar, .ck.ck-toolbar, .ck-toolbar_grouping, .ck-rounded-corners {
	border: none;
}
